<template>
  <div class="vx-row mb-6">
    <div class="vx-col sm:w-1/4 w-full flex items-center">
      <span>Open Key Date</span>
    </div>
    <div class="vx-col sm:w-3/4 w-full">
      <datepicker
        name="date"
        :inline="false"
        v-model="date"
        placeholder="Select Date"
      ></datepicker>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      date: null,
    };
  },
  watch: {
    date(v) {
      this.$emit("data", v);
    },
  },
};
</script>

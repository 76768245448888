<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :data="this.data"
      :max-items="table.length"
      :sst="false"
    >
      <template slot="thead">
        <vs-th>No</vs-th>
        <vs-th>Bus.Area</vs-th>
        <vs-th>Bus.Area</vs-th>
        <vs-th>Customer</vs-th>
        <vs-th>Customer Name</vs-th>
        <vs-th>Doc.No</vs-th>
        <vs-th>Bill.Doc</vs-th>
        <vs-th>Cust PO No.</vs-th>
        <vs-th>Bill.Doc Item</vs-th>
        <vs-th>Doc.Type</vs-th>
        <vs-th>Sp.G/L</vs-th>
        <vs-th>Doc.Date</vs-th>
        <vs-th>Post.Date</vs-th>
        <vs-th>Due Date</vs-th>
        <vs-th>Overdue Date</vs-th>
        <vs-th>TlrcDays</vs-th>
        <vs-th>DueDtTD</vs-th>
        <vs-th>Overdue-TD</vs-th>
        <vs-th>Principal</vs-th>
        <vs-th>Principal</vs-th>
        <vs-th>CGrp</vs-th>
        <vs-th>CGrpTxt</vs-th>
        <vs-th>DChn</vs-th>
        <vs-th>Cust Salesman No</vs-th>
        <vs-th>Cust Salesman Name</vs-th>
        <vs-th>Serang</vs-th>
        <vs-th>Total Open Item (LC)</vs-th>
        <vs-th>Current (LC)</vs-th>
        <vs-th>Total Due (LC)</vs-th>
        <vs-th>Due 15 (LC)</vs-th>
        <vs-th>Due 30 (LC)</vs-th>
        <vs-th>Due 60 (LC)</vs-th>
        <vs-th>Due 90 (LC)</vs-th>
        <vs-th>Due 180 (LC)</vs-th>
        <vs-th>Due 365 (LC)</vs-th>
        <vs-th>Due 730 (LC)</vs-th>
        <vs-th>Due> 730 (LC)</vs-th>
        <vs-th>Refference</vs-th>
        <vs-th>Clearing Doc</vs-th>
        <vs-th>Clearing Date</vs-th>
        <vs-th>Text</vs-th>
        <vs-th>Assignment</vs-th>
        <vs-th>G/L Account</vs-th>
        <vs-th>G/L Account Names</vs-th>
        <vs-th>Account Group</vs-th>
        <vs-th>Account Group Names</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <vs-button
              @click="onClickBtnDetail(tr.customer_visit_id)"
              size="small"
              color="success"
              icon-pack="feather"
              icon="icon-eye"
              title="Detail"
              style="margin-right: 5px"
            />
          </vs-td>
          <vs-td>{{ indextr + 1 }}</vs-td>
          <vs-td>{{ tr.business_area_id }}</vs-td>
          <vs-td>{{ tr.business_area_name }}</vs-td>
          <vs-td>{{ tr.customer_id }}</vs-td>
          <vs-td>{{ tr.customer_name }}</vs-td>
          <vs-td>{{ tr.document_number }}</vs-td>
          <vs-td>{{ tr.bill_document_number }}</vs-td>
          <vs-td>{{ tr.customer_po_number }}</vs-td>
          <vs-td>{{ tr.bill_document_item_number }}</vs-td>
          <vs-td>{{ tr.document_type }}</vs-td>
          <vs-td>{{ tr.special_gl_account }}</vs-td>
          <vs-td>{{ tr.document_date }}</vs-td>
          <vs-td>{{ tr.posting_date }}</vs-td>
          <vs-td>{{ tr.due_date }}</vs-td>
          <vs-td>{{ tr.overdue_date }}</vs-td>
          <vs-td>{{ tr.total_receipt_days }}</vs-td>
          <vs-td>{{ tr.due_date_td }}</vs-td>
          <vs-td>{{ tr.overdue_td }}</vs-td>
          <vs-td>{{ tr.principal_id }}</vs-td>
          <vs-td>{{ tr.principal_name }}</vs-td>
          <vs-td>{{ tr.customer_group_id }}</vs-td>
          <vs-td>{{ tr.customer_group_name }}</vs-td>
          <vs-td>{{ tr.distribution_channel }}</vs-td>
          <vs-td>{{ tr.customer_salesman_id }}</vs-td>
          <vs-td>{{ tr.customer_salesman_name }}</vs-td>
          <vs-td>{{ tr.serang }}</vs-td>
          <vs-td>{{ tr.total_open_item_lc }}</vs-td>
          <vs-td>{{ tr.current_lc }}</vs-td>
          <vs-td>{{ tr.total_due_lc }}</vs-td>
          <vs-td>{{ tr.due_15_lc }}</vs-td>
          <vs-td>{{ tr.due_30_lc }}</vs-td>
          <vs-td>{{ tr.due_60_lc }}</vs-td>
          <vs-td>{{ tr.due_90_lc }}</vs-td>
          <vs-td>{{ tr.due_180_lc }}</vs-td>
          <vs-td>{{ tr.due_365_lc }}</vs-td>
          <vs-td>{{ tr.due_730_lc }}</vs-td>
          <vs-td>{{ tr.due_over_730_lc }}</vs-td>
          <vs-td>{{ tr.reference }}</vs-td>
          <vs-td>{{ tr.clearing_document }}</vs-td>
          <vs-td>{{ tr.clearing_date }}</vs-td>
          <vs-td>{{ tr.text }}</vs-td>
          <vs-td>{{ tr.assignment }}</vs-td>
          <vs-td>{{ tr.gl_account }}</vs-td>
          <vs-td>{{ tr.gl_account_name }}</vs-td>
          <vs-td>{{ tr.account_group }}</vs-td>
          <vs-td>{{ tr.account_group_name }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      baseUrl: "",
      table: this.tableDefaultState(),
    };
  },
  methods: {
    tableDefaultState() {
      return {
        length: 10,
      };
    },
  },
};
</script>

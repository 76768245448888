<template>
  <vx-card :title="title">
    <vs-tabs>
      <vs-tab label="Data">
        <div class="tab-text">
          <div class="vx-row mb-6">
            <div class="vx-col w-1/2">
              <select-operating-unit
                @data="setOperatingUnit"
              ></select-operating-unit>
              <select-territory
                @data="setTerritory"
                ref="select_territory"
              ></select-territory>
              <input-doc-date @data="setDocDate"></input-doc-date>
              <input-posting-date @data="setPostingDate"></input-posting-date>
              <input-open-key-date @data="setOpenKeyDate"></input-open-key-date>
              <select-balance-status
                @data="setBalanceStatus"
              ></select-balance-status>
            </div>
          </div>

          <!-- button -->
          <div class="vx-row mb-6">
            <div class="vx-col flex gap-2">
              <vs-button
                @click="onClickBtnSearch"
                class="w-full"
                color="primary"
                type="filled"
                icon-pack="feather"
                icon="icon-search"
              >
                Search
              </vs-button>
              <vs-button
                @click="onClickBtnReset"
                class="w-full"
                color="danger"
                type="filled"
                icon-pack="feather"
                icon="icon-refresh-cw"
              >
                Reset
              </vs-button>
              <vs-button
                @click="onClickBtnExport"
                class="w-full"
                color="success"
                type="filled"
                icon-pack="feather"
                icon="icon-download"
              >
                Export
              </vs-button>
            </div>
          </div>

          <!-- table -->
          <div class="vx-row mb-2">
            <div class="core vx-col w-full mb-base">
              <datatable :data="datatable"></datatable>
            </div>
          </div>
        </div>
      </vs-tab>

      <vs-tab label="Export">
        <div class="tab-text">
          <export />
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import moment from "moment";
import SelectOperatingUnit from "./_components/select-operating-unit.vue";
import SelectTerritory from "./_components/select-territory.vue";
import Datatable from "./_components/datatable.vue";
import InputDocDate from "./_components/input-doc-date.vue";
import InputPostingDate from "./_components/input-posting-date.vue";
import InputOpenKeyDate from "./_components/input-open-key-date.vue";
import SelectBalanceStatus from "./_components/select-balance-status.vue";
import Export from "./export.vue";

export default {
  components: {
    Datatable,
    Export,
    SelectOperatingUnit,
    SelectTerritory,
    InputDocDate,
    InputPostingDate,
    InputOpenKeyDate,
    SelectBalanceStatus,
  },
  data() {
    return {
      title: "Report Collection AR Aging",
      datatable: [],
      operatingUnit: {
        id: null,
        code: null,
      },
      territorry: {
        id: null,
        code: null,
      },
      docDate: {
        start: null,
        end: null,
      },
      postingDate: {
        start: null,
        end: null,
      },
      openKeydate: null,
      balanceStatus: null,
    };
  },
  mounted() {},
  methods: {
    onClickBtnExport() {},
    setBalanceStatus(selected) {
      this.balanceStatus = selected;
    },
    setDocDate(start, end) {
      this.docDate.start = start;
      this.docDate.end = end;
    },
    setPostingDate(start, end) {
      this.postingDate.start = start;
      this.postingDate.end = end;
    },
    setOpenKeyDate(date) {
      this.openKeydate = date;
    },
    onClickBtnSearch() {},
    setOperatingUnit(id, code) {
      this.operatingUnit.id = id;
      this.operatingUnit.code = code;
    },
    setTerritory(id, code) {
      this.territorry.id = id;
      this.territorry.code = code;
    },
    onClickBtnReset() {
      // reload
      this.$router.go(0);
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
  },
  watch: {
    "operatingUnit.id": function (val) {
      this.$refs.select_territory.getData({
        operating_unit_id: val,
      });
    },
  },
};
</script>

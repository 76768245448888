<template>
  <div class="vx-row mb-6">
    <div class="vx-col sm:w-1/4 w-full flex items-center">
      <span>Posting Date</span>
    </div>
    <div class="vx-col sm:w-3/4 w-full">
      <date-range-picker
        style="min-height: 40px"
        class="w-full"
        ref="picker"
        opens="center"
        :show-week-numbers="true"
        :show-dropdowns="true"
        :locale-data="{ firstDay: 1, format: 'dd mmmm yyyy' }"
        :auto-apply="true"
        v-model="validity"
      />
    </div>
  </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { dateFormat } from "../../../../utils/common";

export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      validity: {
        startDate: null,
        endDate: null,
      },
    };
  },
  filters: {
    dateFormat(date) {
      return dateFormat(date);
    },
  },
  watch: {
    validity(v) {
      this.$emit("data", v);
    },
  },
};
</script>

<template>
  <div class="vx-row mb-6">
    <div class="vx-col sm:w-1/4 w-full flex items-center">
      <span>Status Balance</span>
    </div>
    <div class="vx-col sm:w-3/4 w-full">
      <vs-select
        v-model="selected"
        placeholder="Select Status Balance"
        class="w-full"
      >
        <vs-select-item
          :key="index"
          :value="item.value"
          :text="item.text"
          v-for="(item, index) in options"
        />
      </vs-select>
    </div>
  </div>
</template>
<script>
import { getBalanceStatus } from "../../../../utils/common";
export default {
  data() {
    return {
      selected: null,
      options: [],
    };
  },
  methods: {
    getData() {
      this.options = getBalanceStatus();
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    selected(v) {
      this.selected = v;
      this.$emit("data", v.territory_id, v.code);
    },
  },
};
</script>
